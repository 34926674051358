
.container {
  margin: auto;
  width: 50%;
  padding:10px;

}
.modal {
  margin: auto;
  width: 50%;
  margin-top:3em;
  background: rgba(0, 0, 0, 0.4);
  fill-opacity: 0.7;
  border-radius: 10px;
  border: 0.1em solid #7159c1;
  padding: 30px;
  width: 30em;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}