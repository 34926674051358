.buttonPagarme{
    margin: 0;
    display: inline-block;
    padding: 10px 12px;
    background: rgb(0, 0, 0);
    border: 0.05em solid #7159c1;
    border-radius: 4px;
    font-size: 16px;
    font-weight: normal;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    letter-spacing: 1px;
    vertical-align: middle;
    line-height: 13px;
    color: #ffffff;
    -webkit-transition: opacity cubic-bezier(0.23, 1, 0.32, 1) 0.28s;
    transition: opacity cubic-bezier(0.23, 1, 0.32, 1) 0.28s;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 20px;
  }
  
  .buttonPagarme:hover{
    background-color: #7159c1;
    color: #FFF;
  }
  
  .buttonPagarme:active{
    background: none;
    color: #92c83e;
    margin-top: 1px;
  }
  
  .buttonPagarme:focus{
    box-shadow: none;
  }

  .no-click {
    pointer-events: none;
    background-color: #7159c1;
}